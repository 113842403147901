import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => {

  return (
    <Layout>
      <SEO title="Home" />
      <section className="about-banner background-overlay">
        <h1 className="banner-title">WHO WE ARE</h1>
      </section>

      <section className="about-intro">
        <div className="img-row">
          <div className="col">
            <h2>OUR MISSION</h2>
            <div className="divider"></div>
            <p>Yitnow 熱鬧 is an app developed in Hong Kong for visitors to this beautiful city, and its residents. </p>
            <p>It’s aim is to break down social barriers and get people to meet up in real life.</p>
            <p>Our mission is to connect you with like-minded people who are within walking distance right now, and then give you a place to meet. <em>It’s that simple.</em></p>
          </div>
          <div className="col">
            <div className="img-wrapper">
              <img src="/images/hkflag.jpg" alt="Hong Kong" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
};

